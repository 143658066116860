import { QUANITTY_REGEX } from "@/const";
const Encoding = require('encoding-japanese');

export default {
  methods: {
    // 存在 ================================================================================
    // 必須（空白、空文字のみ）判定 - 未入力、または空白スペース
    validateBlank(value) {
      return value && value.match(/\S/g) ? true : false;
    },
    // 値存在
    validateJustBlank(value) {
      return value ? true : false;
    },

    // 長さ依存 ============================================================
    // 長さ
    validateMaxLen(value, max) {
      return value && value.length != 0 && value.length <= max;
    },

    // バイトサイズ
    validateByteLen(value, max) {
      if (!value) {
        return false
      }
      const unicodeArray = Encoding.stringToCode(value);
      // 文字列からSJISの文字コード配列に変換
      const shiftJisBytes = Encoding.convert(unicodeArray, {
        to: 'SJIS',
        from: 'UNICODE',
        fallback: 'ignore'
      })
      return shiftJisBytes.length <= max;
    },

    // 長さ、空白のみの場合はfalse
    validateMaxLenWithoutOnlyWhitespace(value, max) {
      return !!this.removeWhitespaceIfOnlyWhitespace(value) && value.length !== 0 && value.length <= max;
    },

    // メールアドレス - reg: メールアドレスの形式 ==================================================
    validateMail(value) {
      // const REG = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      const REG =
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/; // 2020.07.21 許可文字に + を追加 ex) tk-nakajima+10@zyyx.jp
      return REG.test(value);
    },

    // パスワード - reg: 英数組み合わせ8-50文字 ==================================================
    validatePsw(value) {
      const REG = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,50}$/i;
      return REG.test(value);
    },

    // 数値 ============================================================
    // 数値
    validateNumber(value) {
      const REG = /^([0-9]\d*|0)$/;
      return REG.test(value);
    },
    // 数値（長さイコール）
    validateNumberEqualLen(value, qualLen) {
      if (value == null || value.length === 0) {
        return true;
      }
      const REG = /^([0-9]\d*|0)$/;
      return REG.test(value) && value && value.length === qualLen;
    },
    // 数値（長さつき）
    validateNumberMaxLen(value, max) {
      const REG = /^([0-9]\d*|0)$/;
      return REG.test(value) && value && value.length <= max;
    },
    // 数値or小数点
    validateNumberOrFloat(value) {
      return isFinite(value);
    },
    validateRequiredNumberEqualLen(value, qualLen) {
      const REG = new RegExp(`^[0-9]{${qualLen}}$`);
      return REG.test(value);
    },
    // 任意項目用、文字（長さイコール）
    validateHalfSizeTextEqualLen(value, len) {
      // null場合、チェックせずにtrueとなる
      if (!value) {
        return true;
      }
      const REG = /^[A-Za-z0-9]*$/;
      return REG.test(value) && value && value.length === len
    },
    // 半角数値の権限チェック
    validateHalfPositiveNumbMax(value, max) {
      // null場合、チェックせずにtrueとなる
      if (!value) {
        return true;
      }
      const REG = /^([0-9]\d*|0)$/;
      return REG.test(value) && value >= 0 && value <= max
    },
    // 整数5桁、小数点1桁、小数部3桁のバリデーション
    validateQuantity(value) {
      if (!value) {
        return true;
      }
      return QUANITTY_REGEX.test(value)
    },
    // 廃棄物数量の必須とフォマットチェック
    validateWasteQuantity(value){
      return this.validateNumberOrFloat(value) && this.validateJustBlank(value);
    },
  },
};
